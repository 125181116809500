import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactHtmlParser from 'react-html-parser';
import {  NavLink } from 'react-router-dom';

const BannerContent = ({data}) =>  {

        let bg = {};
        let overlay = false;
        if(data.backgroundImage && data.backgroundImage.sourceUrl) {
          bg = {backgroundImage:'url('+data.backgroundImage.sourceUrl+')'};
          overlay = true;
        }
        else if(data.backgroundColor) {
          bg = {background:data.backgroundColor};
          overlay = false;
        }

        return (
          <section id={overlay ? 'background_overlay' : 'background_color'} style={bg}> 
            <div className="container">
              <div className="content">
                <div className={!overlay ? 'center-title' : 'page-title title-white'}>
                  {
                    (() => {
                        if (data.title) { 
                                return (
                                  <h3>{data.title}</h3>
                                )
                              }
                      })()  
                  }

                  {
                    (() => {
                        if (data.content) { 
                                return (
                                  ReactHtmlParser(data.content)
                                )
                              }
                      })()  
                  }
                </div>
                {
                    (() => {
                        if (data.buttonLabel && data.buttonUrl) { 
                                return (
                                <div className={data.whiteButton ? 'main-btn btn-white' : 'main-btn'}>
                                    <NavLink to={data.buttonUrl} ><span>{data.buttonLabel}</span></NavLink> 
                                </div>
                                )
                            }
                    })()  
                }
              </div>
            </div>
          </section>
      )
}
  
export default BannerContent;

