import React from 'react';
import {Query} from 'react-apollo'
import gql from 'graphql-tag'
import ReactHtmlParser from 'react-html-parser';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const QUERY = gql`
{
    testimonials {
      edges {
        node {
          title
          content
        }
      }
    }
  }
  `;

  const options = {
    margin: 40,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: false,
    loop: true,
    navText: ["", ""],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        768: {
            items: 2,
        },
        900: {
            items: 3,
        },
        1000: {
            items: 3,

        }
    },
};

// const Testimonials = ({content}) => { }
  class Testimonials extends React.Component {

    render() {
        const { content } = this.props;
        return (
          <Query query={QUERY}>
          {
            ({loading, error, data}) => { 
        
              if(loading) return null;

              return (
                  <section id="testimonials" className="section-padding">
                  <div className="container">
                    <div className="content">
                      <div className="page-title">
                          <h2>{content.title ? content.title : 'what our partners say about us.'}</h2>
                          <div className="title-dots">
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                        </div>
                        <div className="partners-say-wrapper row">
                        <OwlCarousel className='owl-theme' {...options} >
                          {
                              data.testimonials.edges.map((item, key) => {
                                  return(
                                      <div className="item parteners-say-item" key={key}>
                                          <h3>{item.node.title}</h3>
                                          {ReactHtmlParser(item.node.content)}
                                      </div>
                                  )
                              }) 
                          }
                        </OwlCarousel>
                        </div>
                    </div>
                  </div>
                </section> 
              )
            }
          }
          </Query>
        )
    }
}

export default Testimonials;