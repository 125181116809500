import React, { useState } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { CORE_TEXT_FIELD, CORE_EMAIL_FIELD, CORE_TEXTAREA_FIELD, CORE_CHECKBOX_FIELD  } from '../../fragments';

const QUERY = gql`
    ${CORE_TEXT_FIELD} 
    ${CORE_EMAIL_FIELD} 
    ${CORE_TEXTAREA_FIELD} 
    ${CORE_CHECKBOX_FIELD} 
    query getFormData ($id: ID!) {
        gfForm(id: $id, idType: DATABASE_ID) {
            databaseId
            dateCreated
            formFields {
            nodes {
                id
                type
                ...CoreTextField
                ...CoreEmailField
                ...CoreTextareaField
                ...CoreCheckboxField
             }
            }
            pagination{
            lastPageButton {
                text
                type
            }
            }
            title
        }
    }
  `;

var Recaptcha = require('react-recaptcha');

const Contact = ({id}) => {

    // create a variable to store the component instance
    let recaptchaInstance;

    const [inputs, setInputs]     = useState({});
    const [verified, setVerified] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(true);

    if(!id) return null;
    
    const callback =  () => {
        console.log('Done!!!!');
    };
    
    // executed once the captcha has been verified
    const verifyCallback = function (response) {
        setVerified(true);
        setBtnDisabled(false);
    };

    const setAlert = (type='success', message='') => {
        if(!type && !message) return;
        const form = document.getElementById('contact_form');
        const alert = type == 'success' ? 'alert-success' : 'alert-danger';
        form.outerHTML += '<div class="alert '+alert+'">'+message+'</div>';  
        removeLoader();
    }

    const removeLoader = () => {
        document.getElementById('loader-form').remove();
    }

    const removeAlert = () => {
        removeElementsByClass('alert')
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const removeElementsByClass = (className) => {
        const elements = document.getElementsByClassName(className);
        while(elements.length > 0){
            elements[0].parentNode.removeChild(elements[0]);
        }
    }

    const handleSubmit = (e) => { 
        e.preventDefault();  

        if(!verified) {
            setAlert('error', 'Recaptcha validation error');
        }
        else {
            e.target.outerHTML += '<div id="loader-form"><div class="loader"></div></div>';
            removeAlert()
            const formElement = e.target,
                { action, method } = formElement,
                body = new FormData(formElement);
                
                fetch(action, {
                    method,
                    body
                })
                .then(response => response.json())
                .then(response => normalizeGravityFormsResponse(response))
                .then(response => {
                    const type = response.isSuccess ? 'success' : 'error';
                    setAlert(type, response.message);

                    if (type == 'success') {
                        document.getElementById('contact_form').remove();                   
                    }
                })
                .catch((error) => {
                    removeLoader();
                });
        }
      }

      const normalizeGravityFormsResponse = (response) => { 
        const isSuccess = response.is_valid;
        const message = isSuccess ? response.confirmation_message.replace(/<[^>]*>?/gm, '') : 'There was a problem with your submission.';
        const validationError = isSuccess ? {}
          :  Object.fromEntries(
              Object.entries(
                  response.validation_messages
              ).map(([key, value]) => { 
                  removeElementsByClass('form-input-hint');
                  document.getElementsByName(`input_${key}`)[0].outerHTML += ('<div class="form-input-hint">'+value+'</div>');
                  document.getElementsByName(`input_${key}`)[0].parentElement.className += ' has-error' ;
                 })
            );
      
        return {
          isSuccess,
          message,
          validationError,
        };
      };

    return (
        <Query variables={{id}} query={QUERY}>
            {
            ({loading, error, data}) => { 
        
                if(loading) return null;

                if(error) return null;
                
                if(!data || !data.gfForm || !data.gfForm.formFields) return null;
                
                return (
                    <>
                    <form id="contact_form" method='POST' action={"https://api.inquisitivegroup.co.uk/wp-json/gf/v2/forms/"+data.gfForm.databaseId+"/submissions"} onSubmit={handleSubmit}>
                        {
                            data.gfForm.formFields.nodes.map((item, key) => {
                                if(item.type == 'TEXT') {
                                    return (
                                        <div className="form-group" key={key}>
                                            <input required={item.isRequired ? 1 : 0} value={inputs['input_'+item.id] || ""} onChange={handleChange} name={`input_${item.id}`} type="text" className="form-control"  placeholder={item.label.toLowerCase()}  />
                                        </div> 
                                        );
                                 }
                                 else if(item.type == 'EMAIL') {
                                     return(
                                    <div className="form-group" key={key}>  
                                        <input required={item.isRequired ? 1 : 0} value={inputs['input_'+item.id] || ""} onChange={handleChange} name={`input_${item.id}`} type="email" className="form-control"  placeholder={item.label.toLowerCase()} />
                                    </div>
                                     )
                                 }
                                 else if(item.type == 'TEXTAREA') {
                                    return(
                                   <div className="form-group" key={key}>  
                                       <textarea required={item.isRequired ? 1 : 0} value={inputs['input_'+item.id] || ""} onChange={handleChange} name={`input_${item.id}`} className="form-control"  placeholder={item.label.toLowerCase()}></textarea>
                                   </div>
                                    )
                                }
                                else if(item.type == 'CHECKBOX') {
                                    return(
                                   <div className="form-group" key={key}>  
                                        {
                                            item.choices.map((checkbox, key) => {
                                                return (
                                                        <>
                                                            <input key={key} required={item.isRequired ? 1 : 0} type="checkbox" value={checkbox.value} name={`input_${item.id}_${key+1}`}  /> {checkbox.text}
                                                        </>
                                                )
                                            })
                                        }
                                   </div>
                                    )
                                }
                            })
                        }
                    <Recaptcha
                    ref={e => recaptchaInstance = e}
                    render="explicit"
                    sitekey="6LdhlCYUAAAAAACFyjPJb1kjNQbARpKlvsFbjycB"
                    verifyCallback={verifyCallback}
                    onloadCallback={callback}
                    />
                    <button disabled={btnDisabled} type="submit" className="btn submit-button"><span>Submit</span></button>
                    </form>
                    </>
                )
                }
            }
            
    </Query>
    )
}
                                
export default Contact;