import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';


const FullWidthImage = ({data}) =>  {

        return (
            <section id="image_section">
            {
                (() => {
                    if (typeof data.image.sourceUrl !== 'undefined' && data.image.sourceUrl !== '') { 
                            return (
                                
                                <img src={data.image.sourceUrl} alt="Image"/>
                                
                            )
                        }
                })()  
             }
             </section>
      )
}
  
export default FullWidthImage;

