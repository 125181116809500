import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import {Loader} from '../Helper';
import ContactForm from './ContactForm';
import MapComponent from './MapComponent';
import ReactHtmlParser from 'react-html-parser';

const render = (status) => {
    if (status === Status.FAILURE) return null
    return (<Loader />);
  };

const Contact = ({data}) => {
    
    return(
        <section id="contact_bottom">
            <div className="container-fluid">
                <div className="content">
                    <div className="row">
                        <div className="col-lg-6 col-xl-6 col-md-6">
                            <div className="contact-botton-item google-map">   
                            <Wrapper apiKey={"AIzaSyDKMqFPVhDXis7n4vJMvXEPSPdxfiCycGs"} render={render}>
                                <MapComponent latitude={data.mapLocation.latitude} longitude={data.mapLocation.longitude} mapZoom={data.mapLocation.zoom}/>
                            </Wrapper>    
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 col-md-6">
                            <div className="contact-botton-item contact-form"> 
                                <h3>{data.formHeading ? data.formHeading : 'how can we help?'}</h3>
                                {data.formContent ? ReactHtmlParser(data.formContent) : ''}
                                <ContactForm id={data.contactFormId} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact;