import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import 'bootstrap/dist/css/bootstrap.min.css';

const Banner = ({data, home=0}) => {
  const banner = data.HeaderData && data.HeaderData.headerImage ? data.HeaderData.headerImage.sourceUrl : '';
    return (
      <section id="header-bg" style={{ backgroundImage: `url(${banner})` }}>
        <div className="container">   
          <div className={home ? 'header-banner' : 'header-banner header-banner-content'}> 
            <p>{!home ? data.title : ''}</p>
            <h2>{data.HeaderData && data.HeaderData.headerText ? ReactHtmlParser(data.HeaderData.headerText) : data.title}</h2>
          </div>
        </div>
      </section> 
  )
}
export default Banner;