import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import TeamList from './TeamList';

const Team = ({data}) =>  {

    const isLeadership     =  data.isLeadershipTeam;
    
        return (
            <>
            {
                (() => {
                        return (
                            <section id={isLeadership ? 'leadership_team' : 'diverse_team'} className="section-padding">
                                <div className="container">
                                    <div className="content">
                                        <div className={isLeadership ? 'title-small text-center team-main' : 'gradient-title text-center'}>
                                        <h3>{data.title}</h3>
                                        {ReactHtmlParser(data.content)}
                                        </div>
                                        <div className={isLeadership ? 'team-wrapper row' : 'unique-team-wrapper'}>
                                            
                                            <TeamList data={data} /> 
                                            
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )
                })()
            }
            </>
            
      )
}
  
export default Team;

