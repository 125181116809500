import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import {  NavLink } from 'react-router-dom';
import back_image from '../../images/service-image-back.png'
import DigitalIconImage from '../../images/icon-digital.png'


const ThreeColTextTwoColImage = ({data}) =>  {

        return (
            <section id="three_by_two_section" className={data.disableTopPadding ? 'section-padding top-padding-none' : 'section-padding'}>
            <div className="container-fluid">
            <div className="content">
			<div className="approach-top row">
				<div className="col-lg-8 col-xl-8 col-md-8 col-sm-12">
					<div className="approach-top-image">
                    {
                        (() => {
                            if (data.image1.sourceUrl) { 
                                    return (
                                        <img src={data.image1.sourceUrl}/> 
                                    )
                                }
                        })()  
                    }
					</div>
				</div>
				<div className="col-lg-4 col-xl-4 col-md-4 col-sm-12">
					<div className="approach-text gradient-title">
                            {ReactHtmlParser(data.content1)}
                        {
                            (() => {
                                if (data.buttonLabel && typeof data.buttonLink) { 
                                        return (
                                        <div className="main-btn">
                                            <NavLink to={data.buttonLink} ><span>{data.buttonLabel}</span></NavLink> 
                                        </div>
                                        )
                                    }
                            })()  
                        }
					</div>
				</div>
			</div>

			<div className="approach-center"> 
                <div className="gradient-title">
                 {ReactHtmlParser(data.content12)}
                 {
                            (() => {
                                if (data.buttonLabel2 && typeof data.buttonLink2) { 
                                        return (
                                        <div className="main-btn">
                                            <NavLink to={data.buttonLink2} ><span>{data.buttonLabel2}</span></NavLink> 
                                        </div>
                                        )
                                    }
                            })()  
                        }
                </div>
			</div>
            
			<div className="approach-bottom">
				<div className="approach-bottom-image">
                {
                        (() => {
                            if (data.image2.sourceUrl) { 
                                    return (
                                        <img src={data.image2.sourceUrl}/> 
                                    )
                                }
                        })()  
                    }
				</div>
				<div className="gradient-title approach-bottom-text">
                  {ReactHtmlParser(data.content2)}
                  {
                        (() => {
                            if (data.buttonLabel3  && data.buttonLink3) { 
                                    return (
                                    <div className="main-btn">
                                        <NavLink to={data.buttonLink3} ><span>{data.buttonLabel3}</span></NavLink> 
                                    </div>
                                    )
                                }
                        })()  
                     }
				</div>
			</div>
		</div>
    </div>
</section> 
      )
}
  
export default ThreeColTextTwoColImage;

