import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactHtmlParser from 'react-html-parser';
import {BlurbButton} from '../Helper';
import BlurbIcons from './BlurbIcons';

const FullWidthImage = ({main, items}) =>  {

        const bg  = main.enableBlurbGrid && main.blurbType && main.blurbType != 'gradient' && main.backgroundColor ? main.backgroundColor : '#ffffff';
        const addClass = main.additionalClass ? main.additionalClass : '';
        return (
            <section id="blurb_section" className={!main.disableBottomPadding ? 'section-padding '+addClass : 'section-padding bottom-padding-none '+addClass} style={{backgroundColor: `${bg}`}}>
            <div className="container">
              <div className="content">
                <div className={!main.enableBlurbGrid || main.blurbType == 'gradient' ? 'gradient-title text-center blurb-title' : 'title-normal text-center blurb-title'}>
                  <h4>{ReactHtmlParser(main.title)}</h4>
                  {ReactHtmlParser(main.content)}
                  </div>
                
                  {BlurbButton(main, 'after_content')}

                  <BlurbIcons main={main} items={items} />

              </div>
            </div>
          </section>
      )
}
  
export default FullWidthImage;

