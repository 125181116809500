import React from 'react';
import {Query} from 'react-apollo'
import gql from 'graphql-tag'
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './Header';
import Banner from './Banner';
import PageBuilder from './PageBuilder';
import Footer from './Footer';
import {Loader} from './Helper';
import SEO from './SEO';


const QUERY = gql  `
{
  nodeByUri(uri: "/") {
    ... on Page {
    title
    content
    seo {
      canonical
      title
      metaDesc
      metaRobotsNoindex
      metaRobotsNofollow
    }
    HeaderData {
      headerText
      headerImage {
        sourceUrl
      }
    }
    bodyBuilder {
      build {
        contentType
        heroContentType
        fullwidthImage {
          image {
            sourceUrl
          }
        }
        heroContent {
          withBubbleBackground
          image1 {
            id
            sourceUrl
          }
          image2 {
            id
            sourceUrl
          }
          content1
          buttonLabel
          buttonLink
          content12
          buttonLabel2
          buttonLink2
          content2
          buttonLink3
          buttonLabel3
          disableTopPadding
        }
        blurbContentMain {
          enableBlurbGrid
          backgroundColor
          blurbType
          buttonLabel
          buttonPosition
          buttonUrl
          content
          title
          gridColumns
          disableBottomPadding
          additionalClass
        }
        blurbContentRepeater {
          content
          title
          icon {
            sourceUrl
          }
          link
        }
        bannerContent {
          backgroundColor
          buttonLabel
          buttonUrl
          content
          backgroundImage {
            sourceUrl
          }
          title
          whiteButton
        }
        clientList {
          title
          content
          images {
            sourceUrl
            altText
          } 
        }
        contactDetails {
          mapLocation {
            latitude
            longitude
            zoom
          }
          formHeading
          formContent
          contactFormId
        }
        testimonialsContent {
          title
        }
        threeColBlocks {
          block {
            content
            title
            image {
              sourceUrl
            }
            link
          }
        }
        teamList {
          title
          content
          enableTeamFilter
          isLeadershipTeam
          teamCategory {
            name
            databaseId
            slug
          }
        }
      }
    }
  }
}
}
  `;

const HomePage = () => {

  return (
    <Query query={QUERY}>
    {
      ({loading, error, data}) => {  console.log(data)
  
        if(loading) return Loader();  

        if(typeof data.nodeByUri == 'null') return;
        
        return (
            <div id="body-main">
                <SEO data={data.nodeByUri && data.nodeByUri.seo ? data.nodeByUri.seo : ''} />
                <Header /> 
                <Banner data={data.hasOwnProperty('nodeByUri') && data.nodeByUri.hasOwnProperty('HeaderData') ? data.nodeByUri : ''} home='1' />
                <PageBuilder data={data.hasOwnProperty('nodeByUri') && data.nodeByUri.hasOwnProperty('bodyBuilder') && data.nodeByUri.bodyBuilder.hasOwnProperty('build') && typeof data.nodeByUri.bodyBuilder.build != 'null' ? data.nodeByUri.bodyBuilder.build : ''} />
                <Footer />
            </div>
          
        )
      }
    }
    </Query>
  )
}
export default HomePage;