import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import {  NavLink } from 'react-router-dom';


const TwoColTextWithImage = ({data}) =>  {

    const withBubble =  data.withBubbleBackground;

        return (
            <section id={withBubble ? 'page_center' : 'two_by_one_section'} className='section-padding'>
            <div className="container">
            <div className="content">
                    <div className={withBubble ? 'bubble-text' : 'page-title'}>
                        {ReactHtmlParser(data.content1)}
                        {
                                (() => {
                                    if (!withBubble) { 
                                            return (
                                                <div className="title-dots">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            )
                                        }
                                })()  
                        }
                        {
                                (() => {
                                    if ( data.buttonLabel && data.buttonLink) { 
                                            return (
                                            <div className="main-btn">
                                                    <NavLink to={data.buttonLink} ><span>{data.buttonLabel}</span></NavLink>
                                                </div>
                                            )
                                        }
                                })()  
                            }
                    </div>
                    <div className={withBubble ? 'center-right' : 'center-item'}>
                    <div className={withBubble ? 'center-right-image' : 'center-image'}>
                        {
                                (() => {
                                    if (data.image1 && data.image1.sourceUrl) { 
                                            return (
                                                <img src={data.image1.sourceUrl} alt="Image"/>
                                            )
                                        }
                                })()  
                        }
                              
                    </div>
                    </div>
                    <div className={withBubble ? 'bubble-bottom-content' : 'bubbless-text'}>
                        <div className={withBubble ? 'bubble-text' : ''}>
                            {ReactHtmlParser(data.content2)}
                            {
                                    (() => {
                                        if ( data.buttonLabel2 && data.buttonLink2) { 
                                                return (
                                                <div className="main-btn">
                                                        <NavLink to={data.buttonLink2} ><span>{data.buttonLabel2}</span></NavLink>
                                                    </div>
                                                )
                                            }
                                    })()  
                            }
                       </div>
                    </div>
              </div>
                </div>
        </section> 
      )
}
  
export default TwoColTextWithImage;

