import React, { useEffect } from 'react';
import {Query} from 'react-apollo'
import gql from 'graphql-tag'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useParams, useLocation } from 'react-router-dom';
import Header from './Header';
import Banner from './Banner';
import PageBuilder from './PageBuilder';
import Footer from './Footer';
import {Loader} from './Helper';
import ErrorPage from './ErrorPage';
import SEO from './SEO';




const QUERY = gql`
query getPageData ($uri: String!) {
  nodeByUri(uri: $uri) {
    ... on Page {
    title
    content
    seo {
        canonical
        title
        metaDesc
        metaRobotsNoindex
        metaRobotsNofollow
    }
    HeaderData {
      headerText
      headerImage {
        sourceUrl
      }
    }
    bodyBuilder {
      build {
        contentType
        heroContentType
        fullwidthImage {
          image {
            sourceUrl
          }
        }
        heroContent {
          withBubbleBackground
          image1 {
            id
            sourceUrl
          }
          image2 {
            id
            sourceUrl
          }
          content1
          buttonLabel
          buttonLink
          content12
          buttonLabel2
          buttonLink2
          content2
          buttonLink3
          buttonLabel3
          disableTopPadding
        }
        blurbContentMain {
          enableBlurbGrid
          backgroundColor
          blurbType
          buttonLabel
          buttonPosition
          buttonUrl
          content
          title
          gridColumns
          disableBottomPadding
          additionalClass
        }
        blurbContentRepeater {
          content
          title
          icon {
            sourceUrl
          }
          link

        }
        bannerContent {
          backgroundColor
          buttonLabel
          buttonUrl
          content
          backgroundImage {
            sourceUrl
          }
          title
          whiteButton
        }
        clientList {
          fieldGroupName
          title
          content
          images {
            sourceUrl
            altText
          } 
        }
        contactDetails {
          mapLocation {
            latitude
            longitude
            zoom
          }
          formHeading
          formContent
          contactFormId
        }
        testimonialsContent {
          title
        }
        threeColBlocks {
          block {
            content
            title
            image {
              sourceUrl
            }
            link
          }
        }
        teamList {
          title
          content
          enableTeamFilter
          isLeadershipTeam
          teamCategory {
            name
            databaseId
            slug
          }
        }
      }
    }
  }
}
}
  `;

const Page = () => {

  const { pathname } = useLocation();
  const { slug }     = useParams();
  const uri          = !slug ? '' : '/'+slug;
  const clss         = 'page-'+slug;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if(!uri) return null;

  return (
    <Query variables={{uri}} query={QUERY}>
    {
      ({loading, error, data}) => { 
  
        if(loading) return Loader(); 
       
        if(!data.nodeByUri) return (<ErrorPage />);

        return (
            <div id="body-main" className={clss}>
                <SEO data={data.nodeByUri && data.nodeByUri.seo ? data.nodeByUri.seo : ''} />
                <Header /> 
                <Banner data={data.hasOwnProperty('nodeByUri') && data.nodeByUri.hasOwnProperty('HeaderData') ? data.nodeByUri : ''}  home={false} />
                <PageBuilder content={data.nodeByUri && data.nodeByUri.content ? data.nodeByUri.content : ''} data={data.hasOwnProperty('nodeByUri') && data.nodeByUri.hasOwnProperty('bodyBuilder') && data.nodeByUri.bodyBuilder.hasOwnProperty('build') && data.nodeByUri.bodyBuilder.build ? data.nodeByUri.bodyBuilder.build : ''} />
                <Footer />
            </div>
        )
      }
    }
    </Query>
  )
}
export default Page;