import React from 'react';
import {Query} from 'react-apollo';
import gql from 'graphql-tag';
import { NavLink } from 'react-router-dom';

const QUERY = gql`
query getMenuData ($name: ID!) {
  menu(id: $name, idType: NAME) {
      menuItems {
        edges {
          node {
            id
            label
            url
          }
        }
      }
    }
  }
  `;

const Menu = ({name}) => {

  return(
  <Query variables={{name}} query={QUERY}>
    {
        ({loading, error, data}) => { 

            if(loading) return null;
            
              return (     
                <>    
                  {
                    data.menu.menuItems.edges.map((item, key) => {
                        return(
                            <li key={key} className={name == 'Primary Menu' ? 'nav-item' : 'footer-nav-item'}>
                              <NavLink to={`${item.node.url}`} className={name == 'Primary Menu' ? 'nav-link' : 'footer-nav-link'}>{`${item.node.label}`}</NavLink>
                            </li>
                        )
                    })
                  }
                </>                
            )
          }
        }
  </Query>
  )
}
export default Menu;


                