import { gql } from '@apollo/client';

export const CORE_TEXT_FIELD = gql`
  fragment CoreTextField on TextField {
    description
    label
    isRequired
  }
`;

export const CORE_EMAIL_FIELD = gql`
  fragment CoreEmailField on EmailField {
    description
    label
    isRequired
  }
`;

export const CORE_TEXTAREA_FIELD = gql`
  fragment CoreTextareaField on TextAreaField {
    description
    label
    isRequired
  }
`;

export const CORE_CHECKBOX_FIELD = gql`
  fragment CoreCheckboxField on CheckboxField {
    description
    label
    isRequired
    choices {
        isSelected
        text
        value
      }
  }
`;