import React from 'react';
import ApolloClient from 'apollo-boost';
import {ApolloProvider} from 'react-apollo';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import  "./style.css";
import  "./index.css";
import Page from './components/Page'; 
import HomePage from './components/HomePage';
import introspectionQueryResultData from './graphql.schema.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const cache = new InMemoryCache({ fragmentMatcher });

const client = new ApolloClient({
  uri: "https://api.inquisitivegroup.co.uk/graphql",
  cache
});

function Home() {
  
  return (
    <ApolloProvider client={client} >
     <Router>
        <Page />
        <Routes>
           <Route index path="/" className="nav-link" element={<HomePage />}  />
           <Route path="/:slug" className="nav-link" element={<Page />}  />
        </Routes>
        <Page />
     </Router>
    </ApolloProvider>
  );
}

export default Home;
