import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactHtmlParser from 'react-html-parser';

const Clients = ({data}) =>  {
        return (
            <section id="clients" className="section-padding">
                <div className="container">
                <div className="content">
                    <div className="title-small">
                    <h3>{data.title}</h3>
                    {ReactHtmlParser(data.content)}
                    </div>
                    <div className="client-wrapper">
                        {
                            data.images.map((item, key) => {
                                return (
                                    <div className="client-item" key={key}>
                                        <img src={item.sourceUrl} alt={item.altText}/>
                                    </div>
                                )
                            })

                        }
                    </div>
                </div>
                </div>
            </section>
      )
}
  
export default Clients;

