import React from 'react';
import {Query} from 'react-apollo';
import gql from 'graphql-tag';
import Menu from './Menu';
import logo from '../images/logo.png'
import { NavLink } from 'react-router-dom';
import ReactGA from "react-ga4";

const QUERY = gql`
query GetThemeOptions {
  themeOptions {
    themeSettings {
      headerLogo {
        sourceUrl
      }
      googleAnalytics
    }
  }
}
`;

const Header = () => {
    return (
      
      <Query query={QUERY}>
          {
        ({loading, error, data}) => { 

            if(loading) return null;

            const themeSettings = data.themeOptions && data.themeOptions.themeSettings ? data.themeOptions.themeSettings : '';
            const trackingId = themeSettings.googleAnalytics; 
            if(trackingId) {
              ReactGA.initialize(trackingId);
              ReactGA.send("pageview");
            }
            
            return(
              <section className="header">
                <div className="container">   
                  <div className="menu-header">
                    <div className="row">
                      <div className="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-6">
                        <div className="logo">
                        <NavLink to="/"><img src={themeSettings.headerLogo ? themeSettings.headerLogo.sourceUrl : logo} /></NavLink>
                        </div>
                      </div>
                      <div className="col-lg-8 col-xl-8 col-md-8 col-sm-6 col-6">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                              <span className="navbar-toggler-icon"></span>
                              <span className="navbar-toggler-icon"></span>
                              <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarText">
                                <div className="overlay">
                                  <ul className="navbar-nav"> 
                                    <Menu name="Primary Menu" />
                                  </ul>
                              </div>
                            </div>
                        </nav>
                      </div> 
                    </div>
                  </div>
                </div> 
              </section>
              )
            }
          }
      </Query>
    );
}
export default Header;


                