import React from 'react';
import { NavLink } from 'react-router-dom';

const ErrorPage = () => (
    <section id="main-page-content">
	<div class="container">
		<div class="center-content">
			<h1>404</h1>
			<h3>Page not found</h3>
			<p>The page you are looking for does not exist. It might have been removed or deleted.</p>
			<div class="main-btn">
				<NavLink to="/" className="nav-link"><span>Back to home</span></NavLink>
			</div>
		</div>
	</div>
</section>
)

export default ErrorPage;
