import React from 'react';
import {  NavLink } from 'react-router-dom';

export function Loader() {
 return (
            <div id="loader-main"><div className="loader"></div></div>
        );
}


export function BlurbButton(main='', position='after_content') {

    if(!main) return;

    if(main.buttonPosition !== position ) return;

    if (main.buttonLabel && main.buttonUrl) { 
            return (
                <div className="main-btn">
                    <NavLink to={main.buttonUrl} ><span>{main.buttonLabel}</span></NavLink> 
                </div>
            )
    }

    return;
}

