import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import {  NavLink } from 'react-router-dom';


const ColWithText= ({data}) =>  {

    return (
        <section id="page_center" className='section-padding single-column-padder'>
        <div className="container">
        <div className="content">
                <div className='bubble-text'>
                    {ReactHtmlParser(data.content1)}
                    {
                          (() => {
                              if ( data.buttonLabel && data.buttonLink) { 
                                      return (
                                      <div className="main-btn">
                                              <NavLink to={data.buttonLink} ><span>{data.buttonLabel}</span></NavLink>
                                          </div>
                                      )
                                  }
                          })()  
                    }
                </div>
                <div className='center-right-image single-col'>
                    {
                                (() => {
                                    if (data.image1 && data.image1.sourceUrl) { 
                                            return (
                                                <img src={data.image1.sourceUrl} alt="Image"/>
                                            )
                                        }
                                })()  
                        }
                </div>
          </div>
            </div>
    </section> 
  )
}
  
export default ColWithText;

