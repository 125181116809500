import React from 'react';
import {Query} from 'react-apollo';
import gql from 'graphql-tag';
import logo from '../images/footer-logo.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook,faTwitter,faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faPhone,faEnvelope,faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import Menu from './Menu';

const QUERY = gql`
query GetThemeOptions {
  themeOptions {
    themeSettings {
      copyrightText
      email
      location
      phone
      footerLogo {
        sourceUrl
      }
      copyrightText
      facebookUrl
      twitterUrl
      linkedinUrl
    }
  }
}
`;

const Footer = () => {
      return (
        <Query query={QUERY}>
          {
        ({loading, error, data}) => { 

            if(loading) return null;

            const themeSettings = data.themeOptions && data.themeOptions.themeSettings ? data.themeOptions.themeSettings : '';
            
              return (
                <footer>
                <div className="container">
                  <div className="footer-content-wrapper row">
                    <div className="footer-item col-lg-6 col-xl-6 col-md-5">
                      <div className="footer-logo">
                        <img src={themeSettings.headerLogo ? themeSettings.headerLogo.sourceUrl : logo} /> 
                      </div>
                      <div className="footer-bottom">
                        <ul>
                          <Menu name="Footer Menu" /> 
                          <li><a href="#">{themeSettings.copyrightText ? themeSettings.copyrightText : 'Copyright '+(new Date().getFullYear())}</a></li>
                        </ul>
                      </div>
                      <div className="footer-social">
                        <ul>
                          <li><a href={themeSettings.facebookUrl ? themeSettings.facebookUrl : '#'}><FontAwesomeIcon icon={faFacebook} /></a></li>
                          <li><a href={themeSettings.twitterUrl ? themeSettings.twitterUrl : '#'}><FontAwesomeIcon icon={faTwitter} /></a></li>
                          <li><a href={themeSettings.linkedinUrl ? themeSettings.linkedinUrl : '#'}><FontAwesomeIcon icon={faLinkedin} /></a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="footer-item col-lg-3 col-xl-3 col-md-3">
                      <div className="footer-menu">
                        <ul>
                          <Menu name="Footer Menu 2" />
                        </ul>
                      </div>
                    </div>
                    <div className="footer-item col-lg-3 col-xl-3 col-md-4"> 
                      <div className="footer-contact">
                        <ul>
                          <li className="phone"><FontAwesomeIcon icon={faPhone} /> <a href={themeSettings.phone ? 'tel:'+themeSettings.phone : ''}>{themeSettings.phone ? themeSettings.phone : ''}</a></li> 
                          <li className="email"><FontAwesomeIcon icon={faEnvelope} /> <a href={themeSettings.email ? 'mailto:'+themeSettings.email : ''}>{themeSettings.email ? themeSettings.email : ''}</a></li>
                          <li className="location"><FontAwesomeIcon icon={faMapMarkerAlt} /> <a href="/contact">{themeSettings.location ? themeSettings.location : ''}</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </footer> 
              )
            }
          }
      </Query>
    )
  }

export default Footer;


                