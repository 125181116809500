import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import TwoColTextWithImage from './page-components/TwoColTextWithImage'; 
import TwoColTextTwoColImage from './page-components/TwoColTextTwoColImage'; 
import ThreeColTextTwoColImage from './page-components/ThreeColTextTwoColImage'; 
import ColWithText from './page-components/ColWithText';
import FullWidthImage from './page-components/FullWidthImage';
import Blurb from './page-components/Blurb';
import BannerContent from './page-components/BannerContent';
import Clients from './page-components/Clients';
import Contact from './page-components/Contact';
import Testimonials from './page-components/Testimonials';
import Team from './page-components/Team';
import ThreeColBlocks from './page-components/3colBlocks';
import ReactHtmlParser from 'react-html-parser';


const PageBuilder = ({content, data}) => {

    if(!data || typeof data !== 'object' || Object.keys(data).length === 0) {
        return (
            <div className='page_content section-padding'>
                <div className='container'>{ReactHtmlParser(content)}</div>
             </div>
        )
    }

    return (
        <div className="main-body">
            {
                data.map((item, key) => {
                    
                    if(item.contentType === 'hero_content') {
                        if(item.heroContentType === '1col-content') {
                            return <ColWithText key={key} data={item.heroContent} />
                        }
                        if(item.heroContentType === '2col-content') {
                            return <TwoColTextWithImage key={key} data={item.heroContent} />
                        }
                        else if(item.heroContentType === '2by2') {
                            return <TwoColTextTwoColImage key={key} data={item.heroContent} />
                        }
                        else if(item.heroContentType === '3by2') {
                            return <ThreeColTextTwoColImage key={key} data={item.heroContent} />
                        }
                    }
                    else if(item.contentType === 'blurb') {
                        return <Blurb key={key} main={item.blurbContentMain} items={item.blurbContentRepeater} />
                    }
                    else if(item.contentType === 'fullwidth_image') {
                        return <FullWidthImage key={key} data={item.fullwidthImage} />
                    }
                    else if(item.contentType === 'banner_content') {
                        return <BannerContent key={key} data={item.bannerContent} />
                    }
                    else if(item.contentType === 'client_list') {
                        return <Clients key={key} data={item.clientList} />
                    }
                    else if(item.contentType === 'contact_form') {
                        return <Contact key={key} data={item.contactDetails} />
                    }
                    else if(item.contentType === 'testimonials') {
                        return <Testimonials key={key} content={item.testimonialsContent} />
                    }
                    else if(item.contentType === 'team_list') {
                        return <Team key={key} data={item.teamList} />
                    }
                    else if(item.contentType === '3col_blocks') {
                        return <ThreeColBlocks key={key} data={item.threeColBlocks.block} />
                    }
                    
                })
            }
      </div>
  )
}
export default PageBuilder;