import React from "react";
import {Helmet} from "react-helmet";

const SEO = ({data}) => {

    if(!data) return null;

    return(
        <Helmet>
            <title>{data.title}</title>
            {data.canonical ? '<meta name="description" content="'+data.metaDesc+'" />' : ''}
            <meta name="robots" content={data.metaRobotsNofollow +' '+data.metaRobotsNoindex} />
            {data.canonical ? '<link rel="canonical" href="'+data.canonical+'" />' : ''}
        </Helmet>
    )
}
export default SEO;