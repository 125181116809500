import React from "react";
import ReactHtmlParser from 'react-html-parser';
import {BlurbButton} from '../Helper';

const BlurbIcons = ({main, items}) => { 

    if(!main.enableBlurbGrid) return null;

    const columns = main.gridColumns;
    let   col = 'col-lg-4 col-xl-4 col-md-4 col-sm-12';
    if(columns) {
        switch(columns) {
            case '2col':
            col = 'col-lg-6 col-xl-6 col-md-6';
            break;

            case '3col':
            col = 'col-lg-4 col-xl-4 col-md-6 col-sm-12';
            break;
        }
    }

    return (
        <div className="row g-0 blurb-item-wrapper">
            {
                (() => {
                    return (
                        items.map((item, key) => {
                                return (
                                    <div className={col} key={key}>
                                        {
                                            (() => {
                                                if (item.icon && item.icon.sourceUrl) { 
                                                        return (
                                                            <div className="blurb-item">
                                                                <a href={item.link ? item.link : '#'}>
                                                                    <div className={main.blurbType === 'gradient' ? 'item-gradient' : 'item-image'}>
                                                                        <img src={item.icon.sourceUrl}/>
                                                                    </div>
                                                                    <div className={main.blurbType === 'gradient' ? 'gradient-text' : 'item-text'}>
                                                                        <h3>{item.title}</h3>
                                                                        <p>{ReactHtmlParser(item.content)}</p>
                                                                    </div>
                                                                </a>
                                                        </div>
                                                        )
                                                    }
                                            })()  
                                        }
                                    </div>
                                )
                        })
                    )
            })()
        }

        {BlurbButton(main, 'after_blurb')}

        </div>
    )
}

export default BlurbIcons;