import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import {  NavLink } from 'react-router-dom';
import back_image from '../../images/service-image-back.png'


const TwoColTextTwoColImage = ({data}) =>  {

        return (
            <section id="two_by_two_section">
            <div className="container-fluid">
              <div className="content">
              {
                    (() => {
                        if (typeof data.image1.sourceUrl !== 'undefined' &&  data.image1.sourceUrl !== '') { 
                                return (
                                    <div className="service-image-top">
                                    <div className="img-front">
                                      <img src={data.image1.sourceUrl}/>
                                    </div>
                                    <div className="img-back">
                                      <img src={back_image}/>
                                    </div>   
                                  </div>
                                )
                            }
                    })()  
                }
                
                <div className="bubble-text bubble-text-top">
                {ReactHtmlParser(data.content1)}
                {
                    (() => {
                        if (typeof data.buttonLabel !== 'undefined' && typeof data.buttonLink !== 'undefined') { 
                                return (
                                <div className="main-btn">
                                    <NavLink to={data.buttonLink} ><span>{data.buttonLabel}</span></NavLink> 
                                </div>
                                )
                            }
                    })()  
                }
                </div>
                  <div className="row row-service">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="bubble-text bubble-text-bottom">
                      {ReactHtmlParser(data.content2)}
                      {
                        (() => {
                            if (data.buttonLabel2  && data.buttonLink2) { 
                                    return (
                                    <div className="main-btn">
                                        <NavLink to={data.buttonLink2} ><span>{data.buttonLabel2}</span></NavLink> 
                                    </div>
                                    )
                                }
                        })()  
                     }
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="service-bottom-image">
                      {
                        (() => {
                            if (data.image2 &&  data.image2.sourceUrl) { 
                                    return (
                                        <div className="service-image-top">
                                        <div className="img-front">
                                        <img src={data.image2.sourceUrl}/>
                                        </div>
                                        <div className="img-back">
                                        <img src={back_image}/>
                                        </div>   
                                    </div>
                                    )
                                }
                        })()  
                     }
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </section> 
      )
}
  
export default TwoColTextTwoColImage;

