import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import {  Link } from 'react-router-dom';

const ThreeColBlocks = ({data}) =>  {
        return (
        <section id="3col_section">
            <div className="container">
                <div className="company_holder">
                {data.map((item, key) => {
                            return (
                                <div className="item" key={key}>
                                    <div className="col_image">
                                        <img src={item.image.sourceUrl}/>
                                    </div>
                                    <div className="col_content">
                                        <h3>{item.title}</h3>
                                        <p>{ReactHtmlParser(item.content)}</p>
                                    </div>
                                    {
                                        (() => {
                                            if (item.link) { 
                                                    return (
                                                        <div class="main-btn">
                                                            <a target="_blank" href={item.link}><span>Find Out More</span></a> 
                                                        </div>
                                                    )
                                                }
                                        })()  
                                    }
                                    
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
      )
}
  
export default ThreeColBlocks;
