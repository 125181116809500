import React from "react";
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import ReactHtmlParser from 'react-html-parser';
import LinkedInImage from "../../images/linkedin.png";

const QUERY = gql`
query getTaxData ($termObj: [String]) {
        teams(first: 100,
            where: {
             orderby: {
                field: DATE
                order: ASC
              }
              taxQuery: {
                  relation: AND,
                  taxArray: [
                        {
                        terms: $termObj
                        taxonomy: TEAMCATEGORY
                        operator: IN
                        field: SLUG
                      }
                  ]
                }
              }
            )
            {
              edges
              {
                cursor
                node{
                    title
                    teamCategory {
                        edges {
                          node {
                            slug
                          }
                        }
                      }
                    content
                    teamMeta {
                        designation
                        linkedinUrl
                      }
                  featuredImage {
                    node {
                      sourceUrl
                    }
                  }
                }
             }
          }
        }     
  `;

const TeamList = ({ data }) => {

    const teamData = data.teamCategory;
    const isFilterEnabled = data.enableTeamFilter;
    const isLeadership = data.isLeadershipTeam;
    let termObj = [];

    data.teamCategory.map((item) => {
        termObj.push(item.slug);
    })

    const removeClass = (fromClass, removeClass) => {
        const elements = document.getElementsByClassName(fromClass);
        [].forEach.call(elements, function (el) {
            el.classList.remove(removeClass);
        });
    }

    const handleFilter = (slug, e) => {

        const allTeam = document.getElementsByClassName('team-list');
        removeClass('filter-menu', 'active');
        e.target.className += ' active';

        if (slug) {
            const show = 'filter-' + slug;
            for (let i = 0; i < allTeam.length; i++) {
                if (allTeam[i].classList.contains(show)) {
                    allTeam[i].style.display = 'block';
                }
                else {
                    allTeam[i].style.display = 'none';
                }
            }
        }
        else {
            for (let i = 0; i < allTeam.length; i++) {
                allTeam[i].style.display = 'block';
            }
        }
    }


    return (
        <Query variables={{ termObj }} query={QUERY}>

            {
                ({ loading, error, data }) => {

                    if (loading) return null;

                    return (
                        <>
                            {
                                (() => {
                                    if (isFilterEnabled) {
                                        return (
                                            <div className="team-department-wrap row">
                                                <div className="filter-row">
                                                    <div className="department" >
                                                        <h3 onClick={(e) => handleFilter(null, e)} className='filter-menu active'>All</h3>
                                                        {
                                                            teamData.map((item, key) => {
                                                                return (
                                                                    <h3 className="filter-menu" onClick={(e) => handleFilter(item.slug, e)} key={key} >{item.name}</h3>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })()
                            }
                            <div className={isLeadership ? 'team-wrapper row' : 'unique-team-main row'}>
                                {
                                    data.teams.edges.map((item, key) => {

                                        const teamCategory = item.node.teamCategory;
                                        let classNames = '';
                                        let divider = '';
                                        if (teamCategory.edges) {
                                            teamCategory.edges.map((item, key) => {
                                                classNames = divider + 'filter-' + item.node.slug;
                                                divider = ' ';
                                            })
                                        }
                                        return (
                                            <div key={key} className={isLeadership ? 'col-xl-3 col-lg-6 col-sm-12' : 'team-list col-xl-3 col-lg-6 col-sm-12 ' + classNames}>
                                                <div className="team-item">
                                                    <div className="team-image">
                                                        <div className="team-img-holder">
                                                            {
                                                                (() => {

                                                                    if (item.node.featuredImage && item.node.featuredImage.node && item.node.featuredImage.node.sourceUrl) {
                                                                        return (
                                                                            <img src={item.node.featuredImage.node.sourceUrl} alt={item.node.title} />
                                                                        )
                                                                    }

                                                                })()
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="team-title">
                                                        <h3>{item.node.title}</h3>
                                                        <p>{item.node.teamMeta ? item.node.teamMeta.designation : ''}</p>
                                                    </div>
                                                    <div className="team-description">
                                                        {ReactHtmlParser(item.node.content)}
                                                    </div>
                                                    {
                                                        (() => {

                                                            if (isLeadership && item.node.teamMeta.linkedinUrl) {
                                                                return (
                                                                    <div className="btn_linked">
                                                                        <a target="_blank" href={item.node.teamMeta.linkedinUrl}><img src={LinkedInImage} alt="" /></a>
                                                                    </div>
                                                                )
                                                            }

                                                        })()
                                                    }

                                                </div>
                                            </div>

                                        )
                                    })
                                }
                                {
                                    (() => {

                                        if (isLeadership) {
                                            return (
                                                <div className="main-btn btn_contact">
                                                    <a href="/contact"><span>Contact us</span></a>
                                                </div>
                                            )
                                        }

                                    })()
                                }
                            </div>
                        </>
                    )
                }
            }

        </Query>
    )
}

export default TeamList;