import React, { useRef, useEffect } from "react";

const MapComponent = ({latitude, longitude, mapZoom}) =>  {

    const ref = useRef();
  
    useEffect(() => { 
      let map = new window.google.maps.Map(ref.current, {
        center: { lat: parseFloat(latitude), lng: parseFloat(longitude) },
        zoom: parseFloat(mapZoom)
      });

      new window.google.maps.Marker({
        position: { lat: parseFloat(latitude), lng: parseFloat(longitude) },
        map,
      });
    });
  
    return <div ref={ref} id="map" />;
  }

export default MapComponent;
